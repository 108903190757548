<template>
  <div
    v-if="page"
    class="w-full mt-[30px]"
    v-html="page.content"
  >
    <!-- <v-runtime-template :template="replaceHtmlWithVue(page.content)" /> -->
  </div>
  <!-- <div class="col-12">
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffolkradio&tabs=timeline&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
        width="255"
        height="500"
        style="border:none;overflow:hidden"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media"
      />
    </div> -->
</template>
<script>

export default {
  data(){
    return {
      page: null
    }
  },
  fetch(){
    let settings = this.$store.state.settings
    let id = settings['OPTIONS_INDEX_SIDEBAR_ORDER'].value.unique_module_1;
    if(id) {
      this.$axios.get("/pages/" + id).then(response => {
        this.page = response.data.data;
      }).catch(e => {
        this.page = null
      })
    } else {
      this.page = null
    }
  }
}
</script>